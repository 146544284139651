// Classes
import FieldsOptions         from '@/Classes/Records/FieldsOptions'
import { ItemMissingFields } from '@/Classes/Records/ItemMissingFields'
import { RecordHelper }      from '@/Classes/Records/RecordHelper'
import { BuilderManager }    from '@/Classes/Responses/BuilderManager'
import { PrimitiveTools }    from '@/Classes/Static/PrimitiveTools'

// Constants
import { ActionFields } from '@/Constants/Global/ActionFields'
import { AppValues }    from '@/Constants/Global/AppValues'

export function parseCompaniesResponse(data: Array<any>) {
	// Clase Constructora.
	const bm = new BuilderManager(data, ActionFields.Edit)
	const { fields, items, actions } = bm.getElements()

	// Eliminar los campos que no queremos mostrar en la tabla.
	bm.removeFields(fields, [
		'_idAdminCompany', '_idCompany', 'region', 'storages','actions'
	])

	// Aplicar opciones a los campos.
	bm.parseFieldsOptions(fields, new FieldsOptions()
		.add('rut', 'Rut').isSortable().setAlign(null, 'center')
		.add('code', 'Código').isSortable().setAlign(null, 'center')
		.add('name', 'Razón Social').isSortable()
		.add('address', 'Dirección').isSortable().setSearchKey('address.name')
		.add('commune', 'Comuna').isSortable().setAlign(null, 'center').setSearchKey('address.commune')
		.add('type', 'Tipo').isSortable().setAlign(null, 'center')
		.add('isClient', '¿Es Cliente?').isSortable().setAlign(null, 'center')
		.add('isValid', '¿Esta Activa?').isSortable().setAlign(null, 'center')
	)

	// Aplicar un nuevo Orden a todas las columnas visibles.
	bm.rearrangeFields(fields, [
		'rut', 'code', 'name', 'address', 'commune', 'type', 'isClient', 'isValid'
	])

	// Aplicar un valor a los campos e items si no estan presentes.
	bm.parseItemsMissingFields(items, new ItemMissingFields()
		.add('address')
		.add('commune')
	)

	// Definir los formatos que serán aplicados al valor de la celda.
	bm.parseItemsFormatters(items, {
		rut: (v: string) => {
			return v.toUpperCase()
		},

		type: (v: string) => {
			switch (v) {
				case 'C': return 'Cliente'
				case 'P': return 'Proveedor'
				case 'S': return 'Sucursal Interna'
			}
		},

		isClient: (v: boolean) => {
			return v ? 'Sí' : 'No'
		},

		isValid: (v: boolean) => {
			return v ? 'Sí' : 'No'
		}
	})

	// Retornar Objeto con los Campos, Items y Acciones.
	return { fields, items, actions }
}

export function parseStackedCompaniesResponse(data: Array<any>) {
	// Clase Constructora.
	const bm = new BuilderManager(data)
	const { fields, items, actions } = bm.getElements()

	// Eliminar los campos que no queremos mostrar en la tabla.
	bm.removeFields(fields, [
		'_idAdminCompany', '_idCompany', 'address', 'code', 'commune', 'isClient', 'isValid', 'region', 'type'
	])

	// Aplicar opciones a los campos.
	bm.parseFieldsOptions(fields, new FieldsOptions()
		.add('rut', 'Rut').isSortable().setAlign(null, 'center').showDetailsButton()
		.add('name', 'Razón Social').isSortable()
	)

	// Aplicar un nuevo Orden a todas las columnas visibles.
	bm.rearrangeFields(fields, [
		'rut', 'name'
	])

	// Definir los formatos que serán aplicados al valor de la celda.
	bm.parseItemsFormatters(items, {
		rut: (v: string) => {
			return v.toUpperCase()
		},

		isClient: (v: boolean) => {
			return v ? 'Sí' : 'No'
		},

		isValid: (v: boolean) => {
			return v ? 'Sí' : 'No'
		}
	})

	// Definir los campos a mostrar en el Detalle.
	bm.parseItemsDetail(items, {
		[RecordHelper.setFlags('Código', 'code')]: ({ code }) => {
			return code
		},
		[RecordHelper.setFlags('Dirección', 'address', 'address.name')]: ({ address }) => {
			return address
		},
		[RecordHelper.setFlags('Comuna', 'commune', 'address.commune')]: ({ commune }) => {
			return commune
		},
		[RecordHelper.setFlags('Tipo', 'type')]: ({ type }) => {
			switch (type) {
				case 'C': return 'Cliente'
				case 'P': return 'Proveedor'
				case 'S': return 'Sucursal Interna'
			}
		},
		[RecordHelper.setFlags('¿Es Cliente?', 'isClient')]: ({ isClient }) => {
			return isClient ? 'Sí' : 'No'
		},
		[RecordHelper.setFlags('¿Esta Activa?', 'isValid')]: ({ isValid }) => {
			return isValid ? 'Sí' : 'No'
		}
	})

	// Retornar Objeto con los Campos, Items y Acciones.
	return { fields, items, actions }
}

export function parseStoragesResponse(data: Array<any>, removeActionField?: boolean) {
	// Clase Constructora.
	const bm = new BuilderManager(data, ActionFields.Edit)
	const { fields, items, actions } = bm.getElements()

	// Eliminar los campos que no queremos mostrar en la tabla.
	const fieldsToRemove = ['_idStorage', '_idCompany', '_idTechnical', '_idUserAdmin', 'contacts', 'equipments', 'region', 'commune','actions']
	if (removeActionField) fieldsToRemove.push('actions')
	bm.removeFields(fields, fieldsToRemove)

	// Aplicar opciones a los campos.
	bm.parseFieldsOptions(fields, new FieldsOptions()
		.add('code', 'Código').isSortable().setAlign(null, 'center')
		.add('codeLocation', 'Código Planta').isSortable().setAlign(null, 'center')
		.add('name', 'Nombre').isSortable()
		.add('address', 'Dirección').isSortable().setSearchKey('address.name')
		.add('companyName', 'Cliente').isSortable().setSearchKey('company.name')
		.add('technicalName', 'Técnico').isSortable().setAlign(null, 'center').setSearchKey('technical.name')
		.add('userAdminName', 'Adm. Contrato').isSortable().setAlign(null, 'center').setSearchKey('userAdmin.name')
		.add('isValid', '¿Esta Activa?').isSortable().setAlign(null, 'center')
	)

	// Aplicar un nuevo Orden a todas las columnas visibles.
	bm.rearrangeFields(fields, [
		'code', 'codeLocation', 'name', 'address', 'companyName', 'technicalName', 'userAdminName', 'isValid'
	])

	// Definir los formatos que serán aplicados al valor de la celda.
	bm.parseItemsFormatters(items, {
		address: (v: string) => {
			if (PrimitiveTools.Strings.isInvalidOrEmpty(v)) return AppValues.Strings.DEFAULT_EMPTY_STRING
			return v
		},

		companyName: (v: string) => {
			return v ? v : AppValues.Strings.DEFAULT_EMPTY_STRING
		},

		isValid: (v: boolean) => {
			return v ? 'Sí' : 'No'
		},

		technicalName: (v: string) => {
			return v ? v : AppValues.Strings.DEFAULT_EMPTY_STRING
		},

		userAdminName: (v: string) => {
			return v ? v : AppValues.Strings.DEFAULT_EMPTY_STRING
		}
	})

	// Retornar Objeto con los Campos, Items y Acciones.
	return { fields, items, actions }
}

export function parseStackedStoragesResponse(data: Array<any>) {
	// Clase Constructora.
	const bm = new BuilderManager(data)
	const { fields, items, actions } = bm.getElements()

	// Eliminar los campos que no queremos mostrar en la tabla.
	bm.removeFields(fields, [
		'_idStorage', '_idCompany', '_idTechnical', '_idUserAdmin',
		'address', 'codeLocation', 'commune', 'companyName', 'contacts', 'equipments', 'isValid', 'region', 'technicalName', 'userAdminName'
	])

	// Aplicar opciones a los campos.
	bm.parseFieldsOptions(fields, new FieldsOptions()
		.add('code', 'Código').isSortable().setAlign(null, 'center').showDetailsButton()
		.add('name', 'Nombre').isSortable()
	)

	// Aplicar un nuevo Orden a todas las columnas visibles.
	bm.rearrangeFields(fields, [
		'code', 'name'
	])

	// Definir los formatos que serán aplicados al valor de la celda.
	bm.parseItemsFormatters(items, {
		isValid: (v: boolean) => {
			return v ? 'Sí' : 'No'
		}
	})

	// Definir los campos a mostrar en el Detalle.
	bm.parseItemsDetail(items, {
		[RecordHelper.setFlags('Código Planta', 'codeLocation')]: ({ codeLocation }) => {
			return codeLocation
		},
		[RecordHelper.setFlags('Dirección', 'address', 'address.name')]: ({ address }) => {
			if (PrimitiveTools.Strings.isInvalidOrEmpty(address)) return AppValues.Strings.DEFAULT_EMPTY_STRING
			return address
		},
		[RecordHelper.setFlags('Cliente', 'companyName', 'company.name')]: ({ companyName }) => {
			return companyName || AppValues.Strings.DEFAULT_EMPTY_STRING
		},
		[RecordHelper.setFlags('Técnico', 'technicalName', 'technical.name')]: ({ technicalName }) => {
			return technicalName || AppValues.Strings.DEFAULT_EMPTY_STRING
		},
		[RecordHelper.setFlags('Adm. Contrato', 'userAdminName', 'userAdmin.name')]: ({ userAdminName }) => {
			return userAdminName || AppValues.Strings.DEFAULT_EMPTY_STRING
		},
		['¿Es Cliente?']: ({ isClient }) => {
			return isClient ? 'Sí' : 'No'
		},
		[RecordHelper.setFlags('¿Esta Activa?', 'isValid')]: ({ isValid }) => {
			return isValid ? 'Sí' : 'No'
		},
	})

	// Retornar Objeto con los Campos, Items y Acciones.
	return { fields, items, actions }
}